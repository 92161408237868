export default {
  props: ["value"],
  computed: {
    proxyModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
